<template>
  <div class="stepper-container">
    <!-- Top Stepper section  -->

    <div class="stepper">
      <div class="stepper-icon-container iconActive">
        <img class="image" src="@/assets/icons/bag-check.svg" alt="" />
      </div>

      <div class="line " :class="{ active: verifyComplete }"></div>

      <div class="stepper-icon-container" :class="{ iconActive: verifyComplete }">
        <!-- <img src="@/assets/icons/Card-contactless.svg" alt="" /> -->
        <CardUpdateDetailsSvg class="image" :verification="verifyComplete" />
      </div>

      <div class="line" :class="{ active: cardUpdateComplete }"></div>

      <div class="stepper-icon-container" :class="{ iconActive: cardUpdateComplete }">
        <!-- <img src="@/assets/icons/Check-mark.svg" alt="" /> -->
        <CardUpdateFinalStep class="image" :completion="cardUpdateComplete" />
      </div>
    </div>
    <div class="stepperText">
      <div>
        <p class="verify textActive">{{ $t("Verify Captcha") }}</p>
      </div>
      <div>
        <p :class="{ textActive: verifyComplete }" class="update">{{ $t("Update Card") }}</p>
      </div>
      <div>
        <p :class="{ textActive: cardUpdateComplete }" class="success">{{ $t("Status") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    verifyComplete: {
      type: Boolean,
    },
    cardUpdateComplete: {
      type: Boolean,
    },
  },

  data() {
    return {
      image: "https://cdn4.vectorstock.com/i/1000x1000/18/08/credit-card-icon-vector-21541808.jpg",
    };
  },
  components: {
    // FeaturedCarouselPlaceholder: () =>
    //   import(
    //     /* webpackChunkName: "featplaceholder" */ "@/components/placeholders/FeaturedCarouselPlaceholder.vue"
    //   ),
    CardUpdateDetailsSvg: () =>
      import(/* webpackChunkName: "likebutton" */ "@/components/SvgImages/CardUpdateDetailsSvg.vue"),
    CardUpdateFinalStep: () =>
      import(/* webpackChunkName: "likebutton" */ "@/components/SvgImages/CardUpdateFinalStep.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

.textActive {
  color: #fff !important;
}

.stepper {
  &-container {
    max-width: 100%;
    margin: 0 40px;
  }

  padding: 0.5rem 0;

  display: flex;

  justify-content: space-between;

  &-icon-container {
    padding: 6px 12px;
    border: 1px solid $clr-dark-gd10;
    border-radius: 50%;

    background-color: #848486;
    margin-top: 2px;

    .image {
      margin-top: 4px;
    }

    &.iconActive {
      background-color: #fff;
    }
  }

  .line {
    display: block;
    width: 100%;

    height: 2px;
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 2px solid $clr-dark-gd10; /* whichever color you prefer */

    &.active {
      border-bottom: 3px solid #fff; /* whichever color you prefer */
    }
  }
}
.stepperText {
  display: flex;
  justify-content: space-between;

  .verify {
    //margin-right: 10px;
    font-size: 1rem;
    color: #848486;
    font-family: $font-regular;
  }
  .update {
    // margin-right:50px
    font-size: 1rem;
    color: #848486;
    font-family: $font-regular;
  }
  .success {
    margin-left: 10px;
    font-size: 1rem;
    color: #848486;
    font-family: $font-regular;
  }
}
.wrapper-progressBar {
  width: 100%;
}
.progressBar {
  li {
    list-style-type: none;
    float: left;
    width: 33%;
    position: relative;
    text-align: center;
    color: $clr-light-gd4;
    &:before {
      content: "";
      line-height: 30px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      border: 1px solid #ddd;
      display: block;
      text-align: center;
      margin: 0 auto 10px;
      background-color: transparent;
      z-index: 100;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: #ddd;
      top: 15px;
      left: -50%;
      z-index: 0;
    }
    &:first-child {
      &:after {
        content: none;
      }
    }
  }
  li.active {
    color: $clr-light-gd2;
    &:before {
      border-color: $clr-light-gd4;
      background-color: $clr-dark-gd11;
    }
  }
  .active {
    &:after {
      background-color: dodgerblue;
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 576px) {
  .stepper {
    &-container {
      margin: 0 20px;
    }
  }

  .stepperText {
    .verify {
      font-size: 0.75rem;
    }
    .update {
      font-size: 0.75rem;
    }
    .success {
      font-size: 0.75rem;
    }
  }
}
</style>
